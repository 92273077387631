import React from "react";

const Caregiver = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      id="caregiverIcon"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10H1V17C1 19.2091 2.79086 21 5 21V21"
        stroke="#A8AEAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10H26V17C26 19.2091 24.2091 21 22 21V21"
        stroke="#A8AEAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="path-3-outside-1_7291_4937"
        maskUnits="userSpaceOnUse"
        x="7"
        y="9"
        width="13"
        height="17"
        fill="black"
      >
        <rect fill="white" x="7" y="9" width="13" height="17" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 11H9V19.5C9 21.9853 11.0147 24 13.5 24C15.9853 24 18 21.9853 18 19.5V11H13.5Z"
        />
      </mask>
      <path className="middle"
        d="M9 11V9C7.89543 9 7 9.89543 7 11H9ZM18 11H20C20 9.89543 19.1046 9 18 9V11ZM9 13H13.5V9H9V13ZM11 19.5V11H7V19.5H11ZM13.5 22C12.1193 22 11 20.8807 11 19.5H7C7 23.0899 9.91015 26 13.5 26V22ZM13.5 26C17.0899 26 20 23.0899 20 19.5H16C16 20.8807 14.8807 22 13.5 22V26ZM20 19.5V11H16V19.5H20ZM18 9H13.5V13H18V9Z"
        fill={iconColor}
        mask="url(#path-3-outside-1_7291_4937)"
      />
      <circle cx="14" cy="4" r="3" stroke="#A8AEAF" strokeWidth="2" />
      <circle cx="23" cy="5" r="2" stroke="#A8AEAF" strokeWidth="2" />
      <circle cx="5" cy="5" r="2" stroke="#A8AEAF" strokeWidth="2" />
    </svg>
  );
};

export default Caregiver;
