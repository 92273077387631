import React from "react";

const Protection = (props) => {

  return (
    <svg
      id="protectionIcon"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1922 0.4191C12.9745 -0.1397 14.0255 -0.1397 14.8078 0.4191C17.6065 2.4182 20.7549 3.6 24.75 3.6C25.9926 3.6 27 4.60736 27 5.85L27 5.92026C27.0002 8.17067 27.0005 12.5491 25.4556 16.7514C23.8475 21.1255 20.5308 25.3765 14.0251 26.9379C13.6799 27.0207 13.3201 27.0207 12.9749 26.9379C6.46921 25.3765 3.15256 21.1255 1.54446 16.7514C-0.000509351 12.5491 -0.000170324 8.17068 3.75123e-06 5.92026C5.62878e-06 5.8966 7.50632e-06 5.87318 7.50632e-06 5.85C7.50632e-06 4.60736 1.00737 3.6 2.25001 3.6C6.2451 3.6 9.39348 2.4182 12.1922 0.4191ZM2.25001 5.85C2.25001 6.47542 2.25001 7.23991 2.28021 8.09998C2.46731 13.4283 3.81355 22.4253 13.5 24.75C23.1865 22.4253 24.5327 13.4283 24.7198 8.09998C24.75 7.23991 24.75 6.47542 24.75 5.85C20.25 5.85 16.65 4.5 13.5 2.25C10.35 4.5 6.75001 5.85 2.25001 5.85Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1647 9.51462C19.604 9.95396 19.604 10.6663 19.1647 11.1056L12.448 17.8223C12.237 18.0333 11.9509 18.1518 11.6525 18.1518C11.3542 18.1518 11.068 18.0333 10.857 17.8223L7.27482 14.2401C6.83548 13.8007 6.83548 13.0884 7.27482 12.6491C7.71416 12.2097 8.42647 12.2097 8.86581 12.6491L11.6525 15.4358L17.5737 9.51462C18.013 9.07528 18.7254 9.07528 19.1647 9.51462Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Protection;
