import React from "react";

const Piggy = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg id="piggyIcon" width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M-8.40604e-08 24C-3.76351e-08 23.4477 0.447715 23 1 23L26 23C26.5523 23 27 23.4477 27 24C27 24.5523 26.5523 25 26 25L1 25C0.447715 25 -1.30486e-07 24.5523 -8.40604e-08 24Z" fill={iconColor}/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 21C2 20.4477 2.44772 20 3 20L24 20C24.5523 20 25 20.4477 25 21C25 21.5523 24.5523 22 24 22L3 22C2.44772 22 2 21.5523 2 21Z" fill={iconColor}/>
<path fillRule="evenodd" clipRule="evenodd" d="M3 9C1.89543 9 1 8.10457 1 7V5.33333C1 4.46556 1.5596 3.69679 2.38539 3.43011L12.7074 0.0967792C13.1006 -0.0302076 13.5236 -0.0322752 13.918 0.0908604L24.596 3.42419C25.4312 3.68493 26 4.45834 26 5.33333V7C26 8.10457 25.1046 9 24 9H23V18C23 18.5523 22.5523 19 22 19C21.4477 19 21 18.5523 21 18V9H17V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V9H12V18C12 18.5523 11.5523 19 11 19C10.4477 19 10 18.5523 10 18V9H6V18C6 18.5523 5.55228 19 5 19C4.44772 19 4 18.5523 4 18V9H3ZM24 5.33333L13.322 2L3 5.33333V7H24V5.33333Z" fill={iconColor}/>
</svg>

  );
};

export default Piggy;
